
import { defineComponent } from "vue";
import store from "@/store/index";
import { WorkspaceMeta } from "@/store/workspace/types";
import { signInFlow } from "@/main";
import { SignInState } from "@/store/workspace/types";
import AuthGuard from "./AuthGuard.vue";

export default defineComponent({
  name: "Foyer",
  components: {
    AuthGuard,
  },
  data() {
    return {
      joining: false,
      failed: false,
      failMessage: "",
      joined: false,
      workspace: "",
      signInState: SignInState.None,
    };
  },
  methods: {
    login: function () {
      console.log(this.$route);
      this.$gapi.getAuthInstance().then((auth) => {
        auth.signIn({ prompt: "select_account" }).then((result: any) => {
          store.commit("user/updateSignInState", signInFlow());
          store.getters["user/signInState"].then((signInState: any) => {
            if (signInState == SignInState.Success) {
              this.failed = false;
              this.joining = false;
            }
          });
        });
      });
    },
    join: function () {
      this.joining = true;
      const key = this.$route.params.key;
      fetch("https://velocity.keryx.workers.dev/collab?token=" + key, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            console.log(result);
            this.joined = true;
            setTimeout(() => {
              console.log("redirect");
            }, 1000);
          });
        } else {
          response.text().then((result) => {
            console.warn(result);
            this.failed = true;
            if (response.status >= 500)
              this.failMessage =
                "sorry we couldn't complete your request, this ones on us!";
            else if (result == `"Wrong User"`)
              this.failMessage = "This invite was meant for someone else!";
            else this.failMessage = result;
          });
        }
      });
    },
  },
});
